/*
 * Custom code goes here.
 * A template should always ship with an empty custom.css
 */

 @font-face {
  font-family: "Ink Scale";
  src: url("../fonts/Ink-Scale.ttf") format("truetype");
}

html *,
body * {
  font-family: raleway, sans-serif;
}

body {
  background-color: #fff;
  color: #4a4a4a;
}

.hidden {
  display: none;
}

a {
  color: #3486c7;
}

.full-width {
  width: 100%;
}

.text-white {
  color: #fff;
}

.tle-title-separator {
  text-align: center;
  margin-bottom: 1rem;
}

.tle-title-separator img {
  max-height: 12px;
}

h1 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 50px;
  text-transform: uppercase;
  color: #3486c7;
  margin-bottom: 1rem;

  @media(max-width: 767px) {
    font-size: 28px;
  }
}

 
h1,h2
{
  span{
    &.colored 
    {
      color: #ff9b25;
      font-size: inherit;
    
      @media (max-width: 767px) {
        font-size: 20px;
      }
    }
  }
}


h2 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  color: #3486c7;
  margin-bottom: 1rem;

  @media(max-width: 767px) {
    font-size: 20px;
  }
}

h2 span.tle-ink-scale {
  font-family: "Ink Scale";
  font-style: normal;
  font-weight: 400;
  font-size: 90px;
  line-height: 76px;
  text-align: center;
  text-transform: none;
}

.tle-text-primary {
  color: #3486c7;
}

.tle-bg-primary {
  background-color: #3486c7;
}

.tle-text-secondary {
  color: #ff9b25;
}

.tle-bg-secondary {
  background-color: #ff9b25;
}

/*
 * HEADER
 */

div.header-nav {
  background-color: #3486c7;
  height: 35px;
}

div.header-nav p,
div.header-nav a,
div.header-nav span {
  color: #fff;
  font-family: raleway, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
}

div.header-nav span.tle-phone-sav img {
  height: 15px;
  width: 15px;
  margin-right: 0.2rem;
}

.header__container,
.d--flex-between {
  height: 100%;
}

.tle-nav-item {
  margin-left: 25px;
  display: inline-block;
}

.tle-nav-item svg {
  width: 15px;
  height: 15px;
  vertical-align: bottom;
  margin-right: 0.1rem;
}

.header__logo img.logo {
  height: 60px;
  width: auto;
}

#header.l-header {
  /*filter: drop-shadow(0px 4px 10px rgba(41, 108, 237, 0.15));*/
  box-shadow: 0px 4px 10px rgba(41, 108, 237, 0.15);
}

.modal.fade .modal-dialog__offcanvas {
  max-width: 100%;
  width: 100%;
  top: 58px;
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .l-wrapper,
  .l-wrapper--boxed,
  main>.notifications-container {
    max-width: 1470px;
  }
}

@media (min-width: 576px) {

  .container,
  .container-sm,
  .l-wrapper,
  .l-wrapper--boxed,
  main>.notifications-container {
    max-width: 1440px;
  }
}

.blockcart {
  &.cart-preview {
    border: 1px solid $blue;
    border-radius: 50%;
    padding: 0.5rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;

    .shopping-cart {
      svg {
        color: $blue;
      }
    }
  }
}

.header-top__col {
  width: auto;
}

@media (min-width: 1200px) {
  .header-top__col {
    width: auto;
  }
}

@media (min-width: 992px) {
  .header-top__col {
    width: auto;
  }
}

/*
 * BLOCK INSTAGRAM
 */
.tle-block-instagram {
  width: 100%;
  padding: 4rem 0;
}

.tle-block-instagram .tle-block-instagram-images {}

.tle-block-instagram .tle-block-instagram-images img {
  border-radius: 1rem;
  max-width: 100%;
}

.tle-block-instagram .tle-block-instagram-link {
  margin-top: 1rem;
  text-align: center;
}

.tle-block-instagram .tle-block-instagram-link a {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #3486c7;
}

/*
 * BLOCK REASSURANCE
 */
.tle-block-reassurance {
  background: #f5f7fa;
  border-radius: 20px;
  padding: 2rem 0;

  @media(max-width:991px) {

    margin: 2px 33px;

  }

  @media(max-width:390px) {

    padding: 30px 20px;

  }
}

.tle-block-reassurance .tle-block-reassurance-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tle-block-reassurance .tle-block-reassurance-item img {
  height: 38px;
  display: inline-block;
  margin-right: 1rem;
}

.tle-block-reassurance .tle-block-reassurance-item p {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #3486c7;
  margin: 0;
  max-width: 300px;
}

/*
 * BLOCK HESITATE / CONTACT
 */
.tle-block-hesitate {
  max-width: 800px;
  margin: auto;
  text-align: center;
  position: relative;
  padding: 2rem 0;
}

.tle-block-hesitate p {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #212121;
  margin-bottom: 1rem;
}

.tle-block-hesitate a.tle-hesitate-btn {
  display: inline-block;
  padding: 14px 32px;
  background: #ffffff;
  border: 1px solid #3486c7;
  box-shadow: 0px 4px 10px rgba(41, 108, 237, 0.15);
  border-radius: 50px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #3486c7;
}

.tle-hesitate-arrow-btn-contact {
  margin-top: 1rem;
}

.tle-hesitate-arrow-btn-contact img {
  max-height: 50px;
  display: inline-block;
  margin-right: 300px;
}

/*
 * BLOCK FAQ
 */
.tle-block-faq {
  background: linear-gradient(180deg, #3486c7 0%, #619adc 100%);
  border-radius: 40px;
  margin: 2rem 0;
  padding: 40px;

  @media(max-width:767px) {
    text-align: center;
  }
}

.tle-block-faq .tle-faq-cols {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}

.tle-block-faq .tle-faq-cols .tle-faq-col {}

.tle-block-faq .tle-faq-cols .tle-faq-col-first {
  width: 25%;
}

.tle-block-faq .tle-faq-cols .tle-faq-col-first p {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  color: #fff;
  margin-bottom: 1rem;
}

.tle-block-faq .tle-faq-cols .tle-faq-col-first a.tle-faq-btn {
  padding: 14px 32px;
  display: inline-block;
  background: #ffffff;
  border: 1px solid #3486c7;
  box-shadow: 0px 4px 10px rgba(41, 108, 237, 0.15);
  border-radius: 50px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #3486c7;
}

.tle-block-faq .tle-faq-cols .tle-faq-col-first .tle-faq-contact {
  margin-top: 3rem;
}

.tle-block-faq .tle-faq-cols .tle-faq-col-first .tle-faq-contact h3 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.tle-block-faq .tle-faq-cols .tle-faq-col-first .tle-faq-contact div {
  margin: 0.5rem 0;
}

.tle-block-faq .tle-faq-cols .tle-faq-col-first .tle-faq-contact div a {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #fff;
}

.tle-block-faq .tle-faq-cols .tle-faq-col-second {
  width: 50%;
}

.tle-block-faq .tle-faq-cols .tle-faq-item {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(41, 108, 237, 0.15);
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 1.5rem;
  cursor: pointer;
}

.tle-block-faq .tle-faq-cols .tle-faq-item .tle-faq-item-header {
  padding: 0.5rem;
  background-image: url(../img/open-faq-item.png);
  background-size: 16px;
  background-position: right 10px center;
  background-repeat: no-repeat;
}

.tle-block-faq .tle-faq-cols .tle-faq-item.open .tle-faq-item-header {
  background-image: url(../img/close-faq-item.png);
}

.tle-block-faq {
.tle-faq-cols {
.tle-faq-item {
.tle-faq-item-header {
h3 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #3486c7;
  margin: 0;
  @media(max-width:767px)
  {
    max-width: 90%;
  }
}
}
}
}
}

.tle-block-faq .tle-faq-cols .tle-faq-item .tle-faq-item-content {
  padding: 0.5rem;
  display: none;
}

.tle-block-faq .tle-faq-cols .tle-faq-item.open .tle-faq-item-content {
  display: block;
}

.tle-block-faq .tle-faq-cols .tle-faq-item .tle-faq-item-content p {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #4a4a4a;
}

@media (max-width: 992px) {
  .tle-block-faq .tle-faq-cols {
    flex-direction: column;
  }

  .tle-block-faq .tle-faq-cols .tle-faq-col-first,
  .tle-block-faq .tle-faq-cols .tle-faq-col-second {
    width: 100%;
  }

  .tle-block-faq .tle-faq-cols .tle-faq-col-second {
    margin-top: 2rem;
  }
}

/*
 * BLOC KBANNER HOME
 */
.tle-block-home-banner {
  background: url(../img/banner-background-complete.png) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin-bottom: 2rem;
  position: relative;
  min-height: 40vh;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  @media(max-width:767px)
  {
     span{
     &.colored {
      font-size: 28px;
  }
  }
}
}

svg.tle-home-banner-svg-top {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 90%;
  height: auto;
}

svg.tle-home-banner-svg-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom-right-radius: 40px;
}

.tle-block-home-banner h1 {
  z-index: 2;
  width: 100%;
  padding: 1rem 0 0 1rem;
}

@media (min-width: 900px) {
  .tle-block-home-banner h1 {
    width: 80%;
  }
}

@media (min-width: 1200px) {
  .tle-block-home-banner h1 {
    width: 70%;
    padding: 1rem 0 0 2rem;
  }
}

@media (min-width: 1512px) {
  .tle-block-home-banner h1 {
    width: 50%;
    padding: 2rem 0 0 6rem;
  }
}

.tle-block-home-banner .tle-title-separator {
  text-align: left;
  margin-left: 12%;
}

.tle-home-banner-links {
  width: 50%;
  margin-left: 2rem;

  @media(max-width: 767px) {
    width: 100%;
  }
}

.tle-home-banner-links a {
  display: inline-block;
  padding: 14px 32px;
  background: #ffffff;
  border: 1px solid #3486c7;
  box-shadow: 0px 4px 10px rgba(41, 108, 237, 0.15);
  border-radius: 50px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #3486c7;
  margin: 1rem;
  z-index: 50;

  @media(max-width: 767px) {
    padding: 14px 10px;
    margin-left: 0;
  }
}

@media (min-width: 900px) {
  .tle-home-banner-links {
    margin: 2rem;
  }
}

@media (min-width: 1200px) {
  .tle-home-banner-links {
    margin: 3rem;
  }
}

@media (min-width: 1512px) {
  .tle-home-banner-links {
    margin-left: 3rem;
    margin-top: 4rem;
  }
}

.tle-home-banner-reassurance {
  padding: 2rem 0;
}

.tle-home-banner-reassurance {
  .tle-home-banner-reassurance-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media(max-width: 767px) {
      padding: 3px 31px;
    }

    img {
      height: 38px;
      display: inline-block;
      margin-right: 1rem;
    }

    p {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #3486c7;
      margin: 0;
    }
  }

}



/*
 * BLOCK WHO WE ARE
 */
.tle-block-who-we-are {
  padding: 2rem 0;

  h2 {
    margin: 2rem 0;
  }

  p {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    text-align: justify;
    color: #4a4a4a;
    margin-bottom: 2rem;

    @media(max-width:991px) {
      padding: 2px 20px;
      text-align: center;
    }
  }

  a {
    display: inline-block;
    padding: 14px 32px;
    background: #ffffff;
    border: 1px solid #3486c7;
    box-shadow: 0px 4px 10px rgba(41, 108, 237, 0.15);
    border-radius: 50px;
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #3486c7;
  }

  img {
    max-width: 100%;
    border-radius: 50px;

    @media(max-width:991px) {
      display: none;
    }
  }

  .titre_q_s_nous {
    display: flex;
    justify-content: center;
    margin-bottom: 9px;
    align-items: center;

    @media(max-width:991px) {
      flex-direction: column-reverse;
    }

    .logo_q_s_nous {
      margin-right: 18px;
    }
  }

  &.search_tle_block {
    h2 {
      line-height: 23px;
    }

    .content_search_tl {
      p {
        letter-spacing: 0em;
        text-align: center;
        font: 500 16px 'Raleway';
        line-height: normal;
        line-height: 24px;
      }
    }

    .tle-serch-tl {
      margin-top: 17px;
      text-align: center;

      img {
        max-height: 50px;
        display: inline-block;
        margin-right: 300px;
        border-radius: 0;
      }
    }

  }
  .colored {
    margin-left: 8px;
}
}


/*
 * BLOCK SERVICE
 */
.tle-block-service {
  padding: 2rem 0;

  @media(max-width:991px) {
    text-align: center;
  }
}

.tle-block-service p.tle-service-intro {
  text-align: center;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  width: 60%;
  margin: 0rem auto 2rem;
}

.tle-block-service img#img_tle_yt {
  max-width: 100%;
  margin: auto;
  cursor: pointer;
}

.tle-block-service h3 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  color: #3486c7;

  @media(max-width:767px) {
    font-size: 20px;
    margin-top: 27px;
  }
}

.tle-block-service .tle-service-item {
  padding: 20px 10px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(41, 108, 237, 0.15);
  border-radius: 15px;
  width: 85%;
  margin: auto;
  margin-bottom: 1rem;
}

.tle-block-service .tle-service-item h4 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ff9b25;
  text-align: center;
}

.tle-block-service .tle-service-item p {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
  color: #212121;
}

.tle-block-service .tle-service-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  margin: auto;
  margin-top: 2rem;

  @media(max-width:991px) {
    display: block;
    width: fit-content;
  }
}

.tle-block-service .tle-service-buttons a.tle-btn-orange {
  display: block;
  padding: 14px 32px;
  background: #ff9b25;
  border-radius: 50px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;

  @media(max-width:991px) {
    margin-bottom: 20px;
  }
}

.tle-block-service .tle-service-buttons a.tle-btn-return {
  display: block;
  padding: 14px 32px;
  background: #ffffff;
  border: 1px solid #3486c7;
  box-shadow: 0px 4px 10px rgba(41, 108, 237, 0.15);
  border-radius: 50px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #3486c7;
}

#tle_yt_modal {
  display: none;
  position: fixed;
  inset: 0 0 0 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.85);
  flex-direction: column;
  justify-content: center;
  align-items: center;
 div.yle_yt_modal_container {
    padding: 0;
    margin: 0;
       iframe {
      width: 560px;
      height: 315px;
      @media(max-width:767px)
      {
        width: auto;
      }
  }
  }
}



/*
 * BREADCRUMB
 */
.breadcrumb {
  background-color: transparent;
  margin: 30px 0;
  padding: 0;
}

.breadcrumbol .breadcrumb-item,
.breadcrumb .breadcrumb-item a {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #212121;
}

.breadcrumb .breadcrumb-item a:hover {
  text-decoration: underline;
}

.breadcrumb .breadcrumb-item.active.active {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: #ff9b25;
}

.breadcrumb-item+.breadcrumb-item:before {
  content: ">";
}

.breadcrumb .breadcrumb-item.active.active span {
  text-decoration: underline;
}

#footer,
.l-footer {
  background: transparent;
  padding-top: 60px;
}

.l-footer,
.l-footer .account-list a,
.l-footer .cms-page-link {
  color: #fff;
}

.l-footer {
  padding: 0;
  margin: 0;
}

.tle-footer {
  background: linear-gradient(180deg, #3486c7 0%, #619adc 100%);
  border-radius: 40px 40px 0px 0px;
  padding-top: 40px;
}

.tle-footer .tle-before-footer img {
  display: block;
  margin: auto;
  max-height: 64px;
}

.tle-footer .tle-before-footer p {
  width: 75%;
  margin: auto;
  font-size: 12px;
  padding: 1rem 0rem;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.tle-footer .footer__title {
  color: #fff;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.tle-footer {
  .tle-footer-cols {
    margin-top: 2rem;
    ul{
      li {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
       a {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        &:hover{
          color: #fff;
          text-decoration: underline;
        }
      }
    }
    }
  
  }
  }

#footer_tle_partners {
  padding-top: 0.5rem;
  text-align: center;
}

#footer_tle_partners p {
  text-align: center;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0em;
}

#footer_tle_partners a.tle-btn {
  box-sizing: border-box;
  display: inline-block;
  padding: 10px 22px;
  background: #ffffff;
  border: 1px solid #3486c7;
  box-shadow: 0px 4px 10px rgba(41, 108, 237, 0.15);
  border-radius: 50px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #3486c7;
}

#footer_store_info {
  text-align: center;
}

.tle-footer-contact-line {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.tle-footer-contact-pictos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1rem;

  .tle-footer-contact-pictos-item {
    margin: 0 16px;
  }
}

.tle-footer-contact-pictos .tle-footer-contact-pictos-item svg {
  width: 20px;
  height: 20px;
}

/*
 * OVERRIDE ADVANCE TOP MENU DESIGN
 */
 

.adtm_menu_container {
  #adtm_menu {
    ul#menu {
      li {
        &.li-niveau1 {
          height: 50px;
          display: flex;
          align-items: center;
        }

        &:last-child {
          a {
            margin: 0;
            padding: 0;
            span {
              background: $orange;
              border-radius: 3.125rem;
              font-family: "Raleway";
              font-style: normal;
              font-weight: 600;
              font-size: .8125rem;
              line-height: 1rem;
              color: $white;
              display: flex !important;
              align-items: center;
              justify-content: center;
              height: 3.125rem;
              min-width: 11.25rem;
              padding: 0;
              margin: 0;
            }
          }
        }
        div{
        &.adtm_sub {
          width: auto;
          margin-left: 0;
          padding-top: 32px;
          z-index: -999;
          box-shadow: none;
          border: none;
          border-radius: 0px 0px 25px 25px;
          max-width: 1440px;
          left: 0;
          right: 0;
          margin: auto;
          .column_wrap_title {
            margin-bottom: 1rem;
            text-align: center;
            a {
              font-family: "Raleway";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 19px;
              text-transform: uppercase;
              color: #3486c7;
            }
            img {
              display: block;
              margin: auto;
              margin-bottom: 1rem;
            }


          }

           ul{
           &.adtm_elements li {
            margin-bottom: 1rem;
            a {
              font-family: "Raleway";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              color: #212121;
              &:hover {
                text-decoration: underline;
              }
            }
          }

          }
          
        }

        }
        

      }

      .adtm_elements{
      &.adtm_elements_15,
      &.adtm_elements_11,
      &.adtm_elements_12,
      &.adtm_elements_14 {
      li{
      &:last-child {
      a {
        color: #3486c7;
        text-decoration: underline !important;
      }
      }
      }
      }
      }
      li.advtm_menu_8 ,
      li.advtm_menu_9 ,
      li.advtm_menu_7
      {
      div
      {
      &.adtm_sub {
      .column_wrap_title{
      text-align: left;
    }
      }
    }
    }
    }
  }
}


.account-links .link-item,
.address,
.block-categories,
.card,
.card-block,
.left-column .search_filters,
.page-wrapper {
  border: none;
}

.address-header,
.card-header,
.page-header {
  background-color: transparent;
  border: none;
}

.address-footer,
.card-footer,
.page-footer {
  background-color: transparent;
  border: none;
  text-align: center;
}

.page-header h1 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  color: #3486c7;
}

.btn.btn-primary {
  display: block;
  margin: auto;
  padding: 14px 32px;
  background: #ffffff;
  border: 1px solid #3486c7;
  box-shadow: 0px 4px 10px rgba(41, 108, 237, 0.15);
  border-radius: 50px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #3486c7;
}

.btn.btn-orange {
  display: block;
  margin: auto;
  padding: 14px 32px;
  background: #ff9b25;
  border: 1px solid #ff9b25;
  box-shadow: 0px 4px 10px rgba(41, 108, 237, 0.15);
  border-radius: 50px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
}

/*** AUTH PAGES ***/
#authentication .page-content {
  padding: 60px 1.25rem;
}

#authentication .login-form .forgot-password {
  text-align: center;
}

#authentication .login-form .forgot-password a {
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

#authentication .login-form .form-google-connect {
  margin: 40px 0 0;
  text-align: center;
}

#authentication .no-account .divider-no-account {
  justify-content: center;
  display: flex;
  margin: 0 0 60px 0;
  width: 100%;
}

#authentication .no-account .divider-no-account span {
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 50px;
  color: #000;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  height: 50px;
  justify-content: center;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  width: 50px;
}

#authentication .no-account .content-no-account h4 {
  color: #3486c7;
  font-family: "Raleway";
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-transform: uppercase;
}

#authentication .no-account .content-no-account h4 span.tle-ink-scale {
  color: #3486c7;
  display: block;
  font-family: "Ink Scale";
  font-style: normal;
  font-weight: 400;
  font-size: 90px;
  line-height: 76px;
  margin: 15px auto;
  text-align: center;
  text-transform: none;
}

#authentication .no-account .content-no-account h4 span.colored {
  color: #ff9b25;
}

#authentication .no-account .content-no-account p {
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

#authentication .no-account .content-no-account a.btn {
  display: inline-block;
}

#authentication .register-form .register-form-header {
  text-align: center;
}

#authentication .register-form .register-form-header p {
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0;
}

#authentication .register-form .register-form-header a {
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  text-decoration: underline;
}

#authentication .register-form .form-google-connect {
  margin: 40px 0;
  text-align: center;
}

#authentication .page-footer {
  padding: 0 1.25rem;
}

/*** CONTACT ***/
#contact .form-fields {
  margin: 60px auto 0;
}

/*** FORMS ***/
.form-group label,
.form-group .label {
  color: #212121;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
 &.date-accouchement {
    color: #3486c7;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    padding: 25px 0 35px;
    margin: 0;
  }
}

.form-group .form-control,
.search-widget__group .form-control {
  background: #f4f4f4;
  border-radius: 3px;
  border: none;
  box-shadow: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  height: 50px;
  line-height: 24px;
}

.form-group .input-group .btn {
  height: calc(100% - 2px);
}

.form-group .custom-checkbox label {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.form-group textarea.form-control {
  height: auto;
}

.search-widget__btn {
  color: #3486c7;
  height: 100%;
}

.btn-link:hover {
  color: #619adc;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 134, 199, 0.25);
}

@media (min-width: 992px) {

  .checkout-form form,
  .page-content form,
  .page-wrapper--order-confirmation form {
    max-width: 550px;
  }
}

/*** PASSWORD ***/
#password a.account-link {
  color: #3486c7;
}

/*** 404 ***/
#pagenotfound #wrapper {
  margin: 80px auto 0;
}

#pagenotfound #wrapper .page-content {
  margin: 60px auto;
  max-width: 550px;
  text-align: center;
  width: 100%;
}

/*** PRODUCT ***/
#top-product {
  background-color: #f5f7fa;
  border-radius: 25px;
  padding: 20px 20px 40px;

   #top-product-top {
    margin: 0 0 40px;
    h1 {
      color: #3486c7;
      font-family: "Raleway";
      font-size: 30px;
      font-weight: 700;
      line-height: 36px;
      text-transform: none;
    }
    .brand {
      margin: 15px 0;
    }
    .brand a,
    .brand span {
     color: #4a4a4a;
     font-family: "Raleway";
     font-size: 16px;
     font-weight: 600;
     line-height: 20px;
   }
   .avis {
    margin: 15px 0 0;
  }
  .product-title-reviews{
  &.empty {
    border: .1rem solid #ddd;
    border-radius: 10px;
    text-transform: uppercase;
    padding: 6px 8px;
    color: #4A4A4A;
    font-size: 12px;
  }
  }
  }
  
}


#top-product #top-product-left .position-relative {
  background-color: #fff;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 10px rgba(41, 108, 237, 0.15));
  padding: 40px;
}

#top-product #top-product-left .position-relative .btn-link {
  color: #3486c7;
}

#top-product #top-product-left .product-thumbs {
  height: 80px;
  margin: 20px 0 0;
  padding: 0 0 0 2px;
  width: calc(100% - 2px);
}

#top-product #top-product-left .product-thumb {
  border-radius: 10px;
  margin: 0 12.5px;
  overflow: hidden;
}

#top-product #top-product-left .product-thumb.slick-current {
  border: 2px solid #3486c7;
}

#top-product #top-product-center {
  padding: 0 45px;
}

#top-product #top-product-center p {
  color: #4a4a4a;
  font-family: "Raleway";
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
}

#top-product #top-product-center #point-fort {
  background-color: #fff;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 10px rgba(41, 108, 237, 0.15));
  margin: 40px 45px 0 0;
  padding: 20px 45px;
}

#top-product #top-product-center #point-fort .pointfort-title {
  color: #ff9b25;
  font-family: "Raleway";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  text-align: center;
}

#top-product #top-product-center #point-fort .divider {
  background-color: #c5dff2;
  display: block;
  height: 1px;
  margin: 5px 0 15px 0;
  width: 100%;
}

#top-product #top-product-center #point-fort .pointfort-list {
  display: grid;
}

#top-product #top-product-center #point-fort .pointfort-list .pointfort-item {
  align-items: center;
  display: flex;
  margin: 0 0 10px;
}

#top-product #top-product-center #point-fort .pointfort-list .pointfort-item .pointfort-item-title {
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  width: 50%;
}

#top-product #top-product-center #point-fort .pointfort-list .pointfort-item .pointfort-item-stars {
  width: 50%;
}

#top-product {
  #top-product-right {
    .addtocartbox {
      background-color: #fff;
      border-radius: 10px;
      filter: drop-shadow(0px 4px 10px rgba(41, 108, 237, 0.15));
      padding: 20px;

      &.sticky {
        position: fixed;
        max-width: 435px;
        bottom: 0;
        z-index: 1030;
        width: auto;
      }
    }
  }
}

#top-product {
#top-product-right {
.addtocartbox {
.addtocartbox-top {
  background: linear-gradient(180deg, #3486c7 0%, #619adc 100%);
  box-shadow: 0px 4px 10px rgba(41, 108, 237, 0.15);
  border-radius: 10px;
  color: #fff;
  flex-direction: column;
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 600;
  height: auto;
  line-height: 24px;
  padding: 25px 30px;
  position: relative;
  text-align: center;
  width: 100%;
}
}
.product-prices {
  align-items: center;
  display: block;
  gap: 15px;
  margin: 20px auto 0;
  align-content: center;
  margin-bottom: 22px;
  flex-wrap: revert;
  .product__product-price {
    display: flex;
    align-items: center;
    justify-content: center;
    .current-price {
      color: #3486c7;
  }
    .regular-price{
      color: #4A4A4A;
  }

  .discount {
    font-size: 12px;
}
}
 
}
.rent_product_text {
  display: flex;
  font-weight: 700;
  color: #3486c7;
  text-align: center;
  align-items: center;
  text-transform: uppercase;
  height: 18px;
  flex-direction: column;
  margin-top: 22px;
 
}
}

}

#top-product #top-product-right .addtocartbox .addtocartbox-top:before {
  background-image: url(../img/reassurance-smyley-white.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  display: inline-block;
  height: 16px;
  margin: 0 8px -3px -12px;
  width: 24px;
}

#top-product #top-product-right .addtocartbox .addtocartbox-text {
  margin: 20px 0;
  padding: 0 45px;
  text-align: center;
}

#top-product #top-product-right .addtocartbox .addtocartbox-text .addtocartbox-text-item {
  margin: 0 0 20px;
}

#top-product #top-product-right .addtocartbox .addtocartbox-text p {
  color: #4a4a4a;
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

#top-product #top-product-right .addtocartbox .addtocartbox-text p.underline {
  font-weight: 500;
  margin: 0;
  text-decoration: underline;
}

#top-product #top-product-right .addtocartbox .addtocartbox-actions .product-variants {
  align-items: flex-start;
  display: flex;
  gap: 15px;
  margin: 20px auto 0;
}

#top-product #top-product-right .addtocartbox .addtocartbox-actions .product-variants .product-variants-item {
  background-color: #fff;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 10px rgba(41, 108, 237, 0.15));
  padding: 20px;
  text-align: center;
  width: 50%;
}

#top-product #top-product-right .addtocartbox .addtocartbox-actions .product-variants .product-variants-item label {
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

#top-product #top-product-right .addtocartbox .addtocartbox-actions .product-variants .product-variants-item select {
  border: none;
  box-shadow: none;
  color: #3486c7;
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  max-width: 100%;
}

#top-product #top-product-right .addtocartbox .addtocartbox-actions .product-add-to-cart .product-quantity {
  background-color: #fff;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 10px rgba(41, 108, 237, 0.15));
  justify-content: center;
  margin: 0;
  padding: 20px;
  text-align: center;
  width: 50%;
}

#top-product #top-product-right .addtocartbox .addtocartbox-actions .product-add-to-cart .quantity__label {
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin: 0 0 0.5rem;
  width: 100%;
}

#top-product #top-product-right .addtocartbox .addtocartbox-actions .product-add-to-cart .qty {
  margin: 0;
}

#top-product #top-product-right .addtocartbox .addtocartbox-actions .product-add-to-cart .qty .input-group {
  border: none;
  box-shadow: none;
  color: #3486c7;
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

#top-product #top-product-right .addtocartbox .addtocartbox-actions .product-add-to-cart .qty .input-group .input-group-btn .btn {
  font-size: 24px;
  line-height: 24px;
}

#top-product #top-product-right .addtocartbox .addtocartbox-actions .product-add-to-cart #popup-teterelle {
  margin: 20px auto;
  text-align: center;
  width: 100%;
}

#top-product #top-product-right .addtocartbox .addtocartbox-actions .product-add-to-cart #popup-teterelle span {
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-decoration: underline;
}

#top-product #top-product-right .addtocartbox .addtocartbox-actions .product-add-to-cart #popup-teterelle span:before {
  background-image: url(../img/icon-info.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  display: block;
  margin-right: 10px;
  height: 20px;
  width: 20px;
}

#top-product #top-product-right .addtocartbox .addtocartbox-actions .product-add-to-cart .add {
  margin: 0;
  width: 100%;
}

#top-product #top-product-right .addtocartbox .addtocartbox-actions .product-add-to-cart .add .btn.btn-orange {
  color: #fff;
  background: #ff9b25;
  border: 1px solid #ff9b25;
  border-radius: 50px;
  box-shadow: 0px 4px 10px rgba(41, 108, 237, 0.15);
  display: block;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: auto;
  padding: 14px 32px;
  text-transform: none;
}

#top-product #top-product-right .addtocartbox .addtocartbox-actions .product-add-to-cart .add .btn.btn-orange:disabled,
#top-product #top-product-right .addtocartbox .addtocartbox-actions .product-add-to-cart .add .btn.btn-orange.disabled {
  background: #f4c777;
  border: 1px solid #f4c777;
  opacity: 1;
}

#top-product #top-product-right .addtocartbox .addtocartbox-actions .product-add-to-cart #product-availability {
  color: #4a4a4a;
  display: block;
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin: 20px auto 0;
  text-align: center;
  width: 100%;
}

#bottom-product {
  margin-top: 60px;
}

#bottom-product .bottom-product-reassurance {
  margin: 0 -15px;
  padding: 0;
}

#bottom-product .bottom-product-reassurance .container {
  padding: 0;
}

#bottom-product .bottom-product-reassurance .tle-block-reassurance {
  padding: 40px 35px;
}

#bottom-product .bottom-product-reassurance .tle-block-reassurance img {
  margin-right: 10px;
}

#bottom-product .bottom-product-longDesc {
  margin: 60px auto;
}

#bottom-product .bottom-product-longDesc .tabs {
  padding: 0;
}

#bottom-product {
  .bottom-product-longDesc {
  .nav-tabs {
    border: none;
    display: flex;
    justify-content: flex-start;
     a{
     &.nav-link {
      color: #3486c7;
      font-family: "Raleway";
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      padding: 0;
      text-transform: uppercase;
      &.active,
      &.focus,
      &.hover
      {
        border-color: transparent;
        font-weight: 600;
    }
    }
  }
  .nav-item {
    margin-right: 27px;
  }
  }
  .tab-content {
    padding: 30px 0 0;
  p,
 span,
.tab-content label {
  color: #4a4a4a;
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
 .tab-content a {
  color: #3486c7;
}

 .tab-content video {
  margin: 30px auto;
  max-width: 100%;
  width: 100%;
}

  }
  }
  .bottom-product-related {
    background-color: #f5f7fa;
    border-radius: 25px;
    padding: 40px 100px;
    text-align: center;
  }
}







#bottom-product .bottom-product-related h2 {
  color: #3486c7;
  font-family: "Raleway";
  font-size: 30px;
  font-weight: 700;
  line-height: 28px;
}

#bottom-product .bottom-product-related h2 span.tle-ink-scale {
  display: block;
  font-family: "Ink Scale";
  font-style: normal;
  font-weight: 400;
  font-size: 90px;
  line-height: 76px;
  text-align: center;
  text-transform: none;
}

#bottom-product .bottom-product-related h2 span.colored {
  color: #ff9b25;
}

#bottom-product .bottom-product-related .accroche {
  font-family: "Raleway";
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  margin: 20px 0;
}

#bottom-product .bottom-product-related .products .product-miniature {
  flex: 0 0 25%;
  max-width: 25%;
}

#bottom-product .bottom-product-faq {
  margin: 60px auto;
}

#bottom-product .bottom-product-faq .container {
  padding: 0;
}

#bottom-product .bottom-product-faq .tle-block-faq .tle-faq-cols .tle-faq-col-second {
  max-width: 600px;
  width: 100%;
}

@media (max-width: 1511px) {
  #top-product #top-product-center #point-fort {
    margin: 40px 0 0 0;
  }

  #bottom-product #bottom-product-global {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 1200px) {
  #top-product #top-product-center {
    padding: 0 15px;
  }
}

@media (max-width: 992px) {
  #top-product-mobile {
    margin-bottom: 20px;
  }

  #top-product-mobile .slick-slide.product-thumb {
    background: #c5dff2;
    border: none;
    border-radius: 4px;
    height: 8px;
    width: 8px;
  }

  #top-product-mobile .product-thumbs[data-count="1"] {
    width: 15px;
  }

  #top-product-mobile .product-thumbs[data-count="2"] {
    width: 30px;
  }

  #top-product-mobile .product-thumbs[data-count="3"] {
    width: 45px;
  }

  #top-product-mobile .product-thumbs[data-count="4"] {
    width: 60px;
  }

  #top-product-mobile .product-thumbs[data-count="5"] {
    width: 75px;
  }

  #top-product-mobile .product-thumbs[data-count="6"] {
    width: 90px;
  }

  #top-product-mobile .product-thumbs[data-count="7"] {
    width: 105px;
  }

  #top-product-mobile .product-thumbs[data-count="8"] {
    width: 120px;
  }

  #top-product-mobile .product-thumbs[data-count="9"] {
    width: 135px;
  }

  #top-product-mobile .product-thumbs[data-count="10"] {
    width: 150px;
  }

  #top-product-mobile .slick-slide.product-thumb.slick-current {
    background: #3486c7;
  }

  #top-product-mobile .slick-slide img.thumb {
    display: none;
  }

  #top-product {
    border-radius: 25px 25px 0 0;
    padding: 30px 20px;
    text-align: center;
  }

  #top-product #top-product-top h1 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }

  #top-product #top-product-center {
    margin: 0 0 20px;
  }

  #top-product #top-product-center p {
    font-size: 14px;
  }

  #top-product #top-product-right .addtocartbox {
    background-color: transparent;
    border-radius: 0;
    filter: none;
    padding: 0;
  }

  #bottom-product .tle-block-reassurance .tle-block-reassurance-item {
    margin: 15px auto;
  }

  #bottom-product .bottom-product-longDesc .nav-item-label {
    color: #3486c7;
    font-family: "Raleway";
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin: 15px 0;
    position: relative;
    text-transform: uppercase;
  }

  #bottom-product .bottom-product-longDesc .nav-item-label:before {
    background-image: url(../img/close-faq-item.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    content: "";
    display: block;
    height: 21px;
    margin-left: 10px;
    position: absolute;
    right: 0;
    top: -1px;
    width: 18px;
  }

  #bottom-product .bottom-product-longDesc .nav-item.open .nav-item-label:before {
    background-image: url(../img/open-faq-item.png);
  }

  #bottom-product .bottom-product-longDesc .nav-item-content {
    display: none;
    margin: 15px 0;
  }

  #bottom-product .bottom-product-longDesc .nav-item.open .nav-item-content {
    display: block;
  }

  #bottom-product .bottom-product-longDesc .nav-item-content .fade:not(.show) {
    opacity: 1;
  }

  #bottom-product .bottom-product-longDesc .nav-item-content video {
    width: 100%;
  }

  #bottom-product .bottom-product-related {
    padding: 30px 20px 40px;
  }

  #bottom-product .bottom-product-related h2 {
    font-size: 20px;
  }

  #bottom-product .bottom-product-related .accroche {
    font-size: 15px;
    margin: 20px auto 30px;
    max-width: 80%;
  }

  #bottom-product .bottom-product-related .products .product-miniature {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.modal-open #blockcart-modal.modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

#blockcart-modal .modal-content {
  border: none;
  border-radius: 10px;
  box-shadow: none;
  padding: 40px;
  width: 970px;
}

#blockcart-modal .modal-header {
  border: none;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
}

#blockcart-modal .modal-header button {
  align-items: center;
  display: flex;
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  line-height: 16px;
  margin: 0;
  padding: 0;
}

#blockcart-modal .modal-header button span i {
  display: inline;
  color: #212121;
  height: 15px;
  width: 15px;
}

#blockcart-modal .modal-header h3 {
  color: #3486c7 !important;
  font-family: "Raleway";
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin: 20px auto;
  text-transform: uppercase;
}

#blockcart-modal .modal-body .media {
  gap: 30px;
  justify-content: center;
  margin: 0 auto;
  max-width: 400px;
}

#blockcart-modal .modal-body .media .media-box {
  background: #f5f7fa;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 10px rgba(41, 108, 237, 0.15));
  height: 170px;
  padding: 10px;
  overflow: hidden;
  width: 170px;
}

#blockcart-modal .modal-body .media .media-box img {
  margin: 0;
  width: 100%;
}

#blockcart-modal .modal-body .media-body p.product-name {
  color: #3486c7;
  font-family: "Raleway";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

#blockcart-modal .modal-body .media-body span.media-body-label,
#blockcart-modal .modal-body .media-body span.media-body-value {
  color: #4a4a4a;
  font-family: "Raleway";
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

#blockcart-modal .modal-footer {
  border: none;
  padding: 0;
  justify-content: center;
  margin: 0 auto;
  max-width: 620px;
  width: 100%;
}

#blockcart-modal .modal-footer .btn {
  font-family: "Raleway";
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

#blockcart-modal .modal-footer .btn.btn-orange {
  border: 1px solid #ff9b25;
  background: #ff9b25;
  color: #fff;
}

#blockcart-modal .modal-footer .btn.btn-orange:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 155, 37, 0.25);
}

#blockcart-modal .modal-related {
  margin: 20px auto 0;
  max-width: 740px;
  width: 100%;
}

#blockcart-modal .modal-related #tle_popupcartaccessories .products {
  justify-content: center;
}

#blockcart-modal .modal-related #tle_popupcartaccessories .products .product-miniature {
  flex: 0 0 25%;
  max-width: 25%;
  min-width: 180px;
}

@media (max-width: 992px) {
  #blockcart-modal .modal-body .media {
    flex-wrap: wrap;
    max-width: 170px;
    text-align: center;
  }

  #blockcart-modal .modal-body .media .media-box,
  #blockcart-modal .modal-body .media .media-body {
    width: 100%;
  }
}

/*** PRODUCTS LIST ***/
.products .product-miniature {
  flex: 0 0 20%;
  max-width: 20%;
}

.products .product-miniature .card {
  background-color: transparent;
}

.products .product-miniature .card .product__card-img {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 15px;
}

.products .product-miniature .card .card-body {
  padding: 10px 20px;
  text-align: center;
}

.products .product-miniature .card h3 a {
  color: #3486c7;
  font-family: "Raleway";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.products .product-miniature .card a.brand {
  color: #4a4a4a;
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.products .product-miniature .card .price {
  color: #3486c7;
  font-family: "Raleway";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.products .product-miniature .card .goToProduct a {
  align-items: center;
  color: #fff;
  background: #ff9b25;
  border: 1px solid #ff9b25;
  border-radius: 50px;
  box-shadow: 0px 4px 10px rgba(41, 108, 237, 0.15);
  display: flex;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  justify-content: center;
  line-height: 16px;
  margin: 10px auto 0;
  padding: 12px 25px;
  text-transform: none;
  height: 40px;
}

.products .product-miniature .card .goToProduct a:before {
  background-image: url(../img/icon-cart.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  display: block;
  margin-right: 10px;
  height: 20px;
  width: 20px;
}

/*** POPUP TETERELLE **/
#popup-teterelle:hover {
  cursor: pointer;
}

#popup-teterelle-content {
  background-color: rgba(0, 0, 0, 0.25);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1040;
}

#popup-teterelle-content.show {
  align-items: center;
  display: flex;
  justify-content: center;
}

#popup-teterelle-content .teterelle-content {
  width: 970px;
}

#popup-teterelle-content .modal-content {
  border: none;
  border-radius: 10px;
  box-shadow: none;
  padding: 40px;
}

#popup-teterelle-content .modal-header {
  border: none;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
}

#popup-teterelle-content .modal-header button {
  align-items: center;
  display: flex;
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  line-height: 16px;
  margin: 0;
  padding: 0;
}

#popup-teterelle-content .modal-header button span i {
  display: inline;
  color: #212121;
  height: 15px;
  width: 15px;
}

#popup-teterelle-content .modal-header h3 {
  color: #3486c7 !important;
  font-family: "Raleway";
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin: 20px auto;
  text-transform: uppercase;
}

#popup-teterelle-content .modal-body {
  text-align: center;
}

#popup-teterelle-content .modal-body p {
  color: #4a4a4a;
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 auto;
  max-width: 650px;
  width: 100%;
}

#popup-teterelle-content .modal-body .media {
  border-radius: 15px;
  margin: 20px auto 0;
  max-width: 480px;
  overflow: hidden;
  padding: 20px;
  width: 100%;
}

#popup-teterelle-content .modal-body .media img {
  height: auto;
  width: 100%;
}

#popup-teterelle-content .modal-footer {
  border: none;
}

#popup-teterelle-content .modal-footer a {
  align-items: center;
  display: flex;
}

#popup-teterelle-content .modal-footer a:before {
  background-image: url(../img/icon-dl.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  display: block;
  margin-right: 10px;
  height: 24px;
  width: 24px;
}

@media (max-width: 992px) {
  #blockcart-modal .modal-content {
    padding: 30px 65px;
    width: auto;
  }

  .blockcart-modal .modal-body {
    margin-bottom: 20px;
  }

  #blockcart-modal .modal-footer {
    box-shadow: none;
    position: relative;
  }

  #popup-teterelle-content .teterelle-content {
    width: auto;
  }

  #popup-teterelle-content .modal-footer a {
    width: auto;
  }
}

/*** PAGES CATEGORY, SEARCH, NEW PRODUCTS, PROMOTIONS **/
#main.main-tle {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;

  #category-header {
    margin: 0 auto 60px;
    text-align: center;
    width: 100%;

    h1 {
      color: #3486c7;
      font-family: "Raleway";
      font-size: 30px;
      font-weight: 700;
      line-height: 28px;
    }

    #category-description {
      margin: 20px auto 0;
      max-width: 720px;
      width: 100%;

      p {
        color: #4a4a4a;
        font-family: "Raleway";
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 0;
        text-align: center;
      }

      h2 {
        margin-top: -30px;
      }
    }

  }
}

#main.main-tle #left-column {
  background-color: #f5f7fa;
  border-radius: 50px;
  box-shadow: 0px 4px 10px rgba(41, 108, 237, 0.15);
  margin-right: 25px;
  padding: 30px;
  width: calc(25% - 25px);
}

.page-search #main.main-tle #left-column,
.page-new-products #main.main-tle #left-column,
.page-best-sales #main.main-tle #left-column,
.page-prices-drop #main.main-tle #left-column {
  visibility: hidden;
}

#main.main-tle #left-column #search_filters .btn--clearfilter {
  align-items: center;
  background: #ffffff;
  border: 0.5px solid #abbed1;
  border-radius: 5px;
  display: flex;
  font-family: "Raleway";
  font-size: 13px;
  font-weight: 400;
  justify-content: center;
  line-height: 15px;
  padding: 10px;
}

#main.main-tle #left-column #search_filters .btn--clearfilter:hover {
  background-color: #abbed1;
  color: #4a4a4a;
}

#main.main-tle #left-column #search_filters .btn--clearfilter:after {
  background-image: url(../img/icon-close.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  display: block;
  height: 16px;
  margin-left: 10px;
  width: 16px;
}

#main.main-tle #left-column #search_filters p {
  color: #212121;
  font-family: "Raleway";
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
}

#main.main-tle #left-column #search_filters p.facet__title {
  color: #3486c7;
  font-family: "Raleway";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
}

#main.main-tle #left-column #search_filters .collpase--facet label {
  color: #212121;
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

#main.main-tle #left-column #search_filters .facet__block {
  border-top: 1px solid #abbed1;
  padding: 15px 0 0;
  position: relative;
}

#main.main-tle #left-column #search_filters .facet__block:hover {
  cursor: pointer;
}

#main.main-tle #left-column #search_filters .facet__block:before {
  background-image: url(../img/icon-down.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  display: block;
  height: 16px;
  margin-left: 10px;
  position: absolute;
  right: 0;
  top: 20px;
  width: 16px;
}

#main.main-tle #left-column #search_filters .facet__block.open:before {
  background-image: url(../img/icon-open.svg);
}

#main.main-tle #left-column #search_filters .facet__block:not(:last-child) {
  border-bottom: none;
}

#main.main-tle #left-column #search_filters .facet__block .collpase--facet {
  display: none;
}

#main.main-tle #left-column #search_filters .facet__block.open .collpase--facet {
  display: block;
}

#main.main-tle #products {
  background-color: #f5f7fa;
  border-radius: 50px;
  box-shadow: 0px 4px 10px rgba(41, 108, 237, 0.15);
  padding: 40px;
  width: 75%;
}

#main.main-tle #products .notFoundText {
  text-align: center;
}

@media (max-width: 992px) {
  .breadcrumb {
    justify-content: center;
  }

  #main.main-tle #category-header h1 {
    font-size: 20px;
  }

  #main.main-tle #category-header #category-description p {
    font-size: 14px;
  }

  #main.main-tle #left-column {
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    margin: 0 0 50px;
    width: 100%;
  }

  #main.main-tle #left-column #product-count {
    text-align: center;
  }

  #main.main-tle #left-column #product-count {
    color: #4a4a4a;
    font-family: "Raleway";
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
  }

  #main.main-tle #products {
    width: 100%;
  }

  .page-search #main.main-tle #left-column,
  .page-new-products #main.main-tle #left-column,
  .page-best-sales #main.main-tle #left-column,
  .page-prices-drop #main.main-tle #left-column {
    display: none;
  }

  .products .product-miniature {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

/*** PAGINATION ***/
.pagination li,
.product-comments__pagination ul li {
  margin: 0 10px;
}

.page-link,
.product-comments__pagination li span {
  align-items: center;
  background-color: transparent;
  border: none;
  color: #4a4a4a;
  display: flex;
  font-family: "Raleway";
  font-size: 18px;
  font-weight: 400;
  height: 32px;
  justify-content: center;
  line-height: 20px;
  margin-left: -1px;
  padding: 0.5rem 0.75rem;
  width: 32px;
}

.page-item.active .page-link,
.page-item.active .product-comments__pagination li span,
.product-comments__pagination li.active .page-link,
.product-comments__pagination li.active span,
.product-comments__pagination li .page-item.active span {
  background: linear-gradient(180deg, #3486c7 0%, #619adc 100%);
  border-color: transparent;
  border-radius: 50px;
}

/**
 * MODULE TLE BLOCK SEO
 */
#tle_category_footer {
  width: 100%;
  margin-top: 60px;
}

#tle_category_footer p {
  max-width: 75%;
  margin: auto;
  text-align: center;
}

/*** CART FUNNEL ***/
.page-cart .card-body .product-line-grid,
.page-cart .card-body .media {
  align-items: center;
  display: flex;
}

.page-cart .card-body .cart-item:not(:last-child) {
  border-bottom: 0;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
}

.page-cart .card-body .media .media-img {
  align-items: center;
  background-color: #f5f7fa;
  border-radius: 15px;
  display: flex;
  filter: drop-shadow(0 4px 10px rgba(41, 108, 237, 0.15));
  justify-content: center;
  margin-right: 1rem;
  padding: 10px;
}

.page-cart .card-body .media .media-img img {
  margin-right: 0;
}

.page-cart .card-body .media .media-body {
  text-align: center;
}

.page-cart .card-body .media .media-body .product-line__title {
  color: #3486c7;
}

.page-cart .card-body .media .media-body .small,
.page-cart .card-body .product-line-grid-right .product-reference,
.page-cart .card-body .product-line-grid-right .product-quantity,
.page-cart .card-body .product-line-grid-right .product-price {
  color: #4a4a4a;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

.page-cart .card-body .product-line-grid-right a.remove-from-cart {
  align-items: center;
  display: flex;
  color: #4a4a4a;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

.page-cart .card-body .product-line-grid-right a.remove-from-cart:before {
  content: url("../img/icon-delete.svg");
  display: block;
  margin-top: 7px;
  margin-right: 0.5rem;
}

.page-cart .cart-grid-right {
  padding-left: 25px;
}

.page-cart .cart-summary {
  background-color: #f5f7fa;
  border-radius: 15px;
  filter: drop-shadow(0 4px 10px rgba(41, 108, 237, 0.15));
  margin-bottom: 30px;
  padding: 50px 62px;
}

.page-cart .cart-summary .card-body {
  padding: 0;
}

.page-cart .cart-summary .card-footer {
  padding: 20px;
}

.page-cart .cart-summary h3.recap-title {
  color: #3486c7;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;
  padding-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
}

.page-cart .cart-summary .cart-detailed-totals .recap-articles {
  color: #4a4a4a;
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 auto 20px;
  text-align: center;
}

.page-cart .cart-summary .cart-detailed-totals .cart-summary-lines {
  border-bottom: 1px solid #c5dff2;
  border-top: 1px solid #c5dff2;
  padding: 20px 0;
}

.page-cart .cart-summary .cart-detailed-totals .cart-summary-line {
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.page-cart .cart-summary .cart-detailed-totals .cart-summary-line .value {
  text-transform: uppercase;
}

.page-cart .cart-summary .cart-detailed-totals .cart-summary-line.cart-total {
  background-color: transparent;
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  padding: 20px 0 0;
}

.page-cart .cart-summary .cart-detailed-totals .cart-summary-line.cart-total .label {
  text-transform: uppercase;
}

.page-cart .cart-summary-bottom {
  background: linear-gradient(180deg, #3486c7, #619adc);
  box-shadow: 0 4px 10px rgba(41, 108, 237, 0.15);
  border-radius: 10px;
  color: #fff;
  flex-direction: column;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  height: auto;
  line-height: 24px;
  padding: 25px 30px;
  position: relative;
  text-align: center;
  width: 100%;
}

.page-cart .cart-summary-bottom:before {
  background-image: url(../img/reassurance-smyley-white.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  display: inline-block;
  height: 16px;
  margin: 0 8px -3px -12px;
  width: 24px;
}

.page-cart .cart-reassurance {
  margin-top: 60px;
}

.page-cart .cart-reassurance .container {
  padding: 0;
}

.page-order #js-checkout-process {
  background: #ffffff;
  border: 0.5px solid #c5dff2;
  border-radius: 25px;
  box-shadow: 0px 4px 10px rgba(41, 108, 237, 0.15);
  padding: 20px 50px;
}

.page-order #js-checkout-process .card-header {
  border-bottom: 0.5px solid #c5dff2;
  padding: 30px 0;
}

.page-order #js-checkout-process section:last-child() .card-header {
  border-bottom: 0;
}

.page-order 
{
#js-checkout-process {
.step-title {
  align-items: center;
  color: #3486c7;
  display: flex;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  justify-content: space-between;
  line-height: 31px;
  text-transform: none;
@media(max-width:767px)
{ 
  font-size: 16px;
}
   .left-checkout-process {
    align-items: center;
    display: flex;
  }
  
    .step-number {
    align-items: center;
    background: #f5f7fa;
    border-radius: 50px;
    display: flex;
    height: 50px;
    justify-content: center;
    margin-right: 30px;
    width: 50px;
  }
  
    .done {
    position: absolute;
    align-items: center;
    background: #3486c7;
    border-radius: 50px;
    color: #fff !important;
    display: flex;
    height: 50px;
    justify-content: center;
    margin-right: 30px;
    width: 50px;
  }

}
}
}



.page-order #js-checkout-process .step-edit {
  align-items: center;
  display: flex;
}

.page-order #js-checkout-process .step-edit:before {
  content: url("../img/icon-edit.svg");
  display: block;
  margin-top: 4px;
  margin-right: 0.5rem;
}

.page-order {
#js-checkout-process{
 .content {
 .invoice-address
 {
  border-bottom: 0;
  justify-content: space-between;
  margin: 0;
  max-width: 550px;
  width: 75%;
  @media(max-width:767px)
  {
    width: 100%;
  }
 }
 .nav-tabs {
  border-bottom: 0;
  justify-content: space-between;
  margin: 0;
  max-width: 550px;
  width: 75%;
  @media(max-width:767px)
  {
    width: 100%;
    display: block;
  }
 .nav-link {
    background-color: #fff;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    margin: 0;
    padding: 0;
    &.active {
      background-color: #fff;
      color: #3486c7;
      border-color: transparent;
    }
  }
 
}
}
}
}





.page-order {
#js-checkout-process {
form {
  margin: 0;
  max-width: 550px;
  width: 75%;
@media(max-width:767px)
{
  width: 100%;
}
}
}
}

.page-order #js-checkout-process form .btn {
  margin: 0;
}

.page-order #js-checkout-process form .selected .card {
  border-color: #3486c7;
}

.page-order #js-checkout-process form .address-item .card .card-header {
  border-bottom: 0;
  padding: 20px;
  text-align: left;
}

.page-order #js-checkout-process form .address-item .card .card-header .custom-control-label {
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
}

.page-order #js-checkout-process form .address-item .card .card-body {
  border-bottom: 0.5px solid #c5dff2;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding: 0 20px 20px;
}

.page-order #js-checkout-process form .address-item .card .card-footer {
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  justify-content: space-between;
}

.page-order #js-checkout-process form .address-item .card .card-footer a.edit-address,
.page-order #js-checkout-process form .address-item .card .card-footer a.delete-address {
  align-items: center;
  display: flex;
}

.page-order #js-checkout-process form .address-item .card .card-footer a.edit-address:before {
  content: url("../img/icon-edit.svg");
  display: block;
  margin-top: 4px;
  margin-right: 0.5rem;
}

.page-order #js-checkout-process form .address-item .card .card-footer a.delete-address:before {
  content: url("../img/icon-trash.svg");
  display: block;
  margin-top: 4px;
  margin-right: 0.5rem;
}

.page-order #js-checkout-process .js-address-form form a.card--address,
.page-order #js-checkout-process .js-address-form form a.invoice-address {
  border: 0;
  text-align: left;
}

.page-order #js-checkout-process .js-address-form form .card-body_add-address {
  align-items: center;
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  justify-content: center;
  padding: 30px 0;
}

.page-order #js-checkout-process .js-address-form form .card-body_add-address .material-icons {
  color: #3486c7;
  margin-right: 0.5rem;
}

.page-order #js-checkout-process .js-address-form .text-adress-tle {
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: underline;
}

.page-order #js-checkout-process .js-address-form .btn.continue {
  margin: 0;
}

.page-order #js-checkout-process .delivery-options-list .form-fields {
  margin-bottom: 35px;
}

.page-order #js-checkout-process .delivery-options-list .delivery-option {
  background: #f5f7fa;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 15px 20px;
}

.page-order #js-checkout-process .delivery-options-list .delivery-option .row {
  align-items: center;
}

.page-order #js-checkout-process .delivery-options-list .delivery-option label {
  margin-bottom: 0;
}

.page-order #js-checkout-process .delivery-options-list .delivery-option label span.carrier-name,
.page-order #js-checkout-process .delivery-options-list .delivery-option label span.carrier-delay,
.page-order #js-checkout-process .delivery-options-list .delivery-option label span.carrier-price {
  color: #212121;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.page-order #js-checkout-process .delivery-options-list .order-options label {
  color: #3486c7;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding: 25px 0 35px;
  margin: 0;
  text-transform: uppercase;
}

.page-order #js-checkout-process .delivery-options-list .order-options textarea.form-control {
  background: #f5f7fa;
  border-radius: 10px;
}

.page-order #js-checkout-process .payment-options {
  margin: 0;
  max-width: 550px;
  width: 75%;
}

.page-order #js-checkout-process .payment-options .payment-option {
  background: #f5f7fa;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 15px 20px;
}

.page-order #js-checkout-process .payment-options .payment-option input {
  position: relative;
}

.page-order #js-checkout-process .payment-options .payment-option label {
  color: #212121;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  width: 100%;
}

.page-order #js-checkout-process .payment-options .alert-info {
  color: #212121;
  background-color: transparent;
  border-color: transparent;
}

.page-order #js-checkout-process .payment-options .alert-info p,
.page-order #js-checkout-process .payment-options .alert-info dt,
.page-order #js-checkout-process .payment-options .alert-info dd {
  color: #212121;
  font-size: 14px;
  line-height: 24px;
}

.page-order #js-checkout-process #conditions-to-approve.alert-info {
  background-color: transparent;
  border-color: transparent;
  color: #212121;
  font-size: 12px;
  line-height: 18px;
  margin: 25px 0 35px !important;
  padding: 0;
}

.page-order #js-checkout-process #conditions-to-approve.alert-info a {
  color: #212121;
  text-decoration: underline;
}

.page-order #js-checkout-process button[type="submit"] {
  margin: 0;
}

.page-order .order-reassurance {
  margin-top: 60px;
}

.page-order .order-reassurance .container {
  padding: 0;
}

.page-order .order-checkout {
  padding-left: 25px;
}

.page-order .order-checkout #js-checkout-summary {
  background-color: #f5f7fa;
  border-radius: 15px;
  filter: drop-shadow(0 4px 10px rgba(41, 108, 237, 0.15));
  margin-bottom: 30px;
  padding: 50px 62px;
}

.page-order .order-checkout h3.recap-title {
  color: #3486c7;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;
  padding-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
}

.page-order .order-checkout .card-body {
  padding: 0;
}

.page-order .order-checkout .card-body p {
  color: #4a4a4a;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
}

.page-order .order-checkout .card-body .cart-summary-subtotals-container {
  border-top: 1px solid #c5dff2;
  border-bottom: 1px solid #c5dff2;
  padding: 20px 0;
}

.page-order .order-checkout .card-body .cart-summary-line span {
  color: #4a4a4a;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.page-order .order-checkout .card-body .cart-summary-line span.value {
  text-transform: uppercase;
}

.page-order .order-checkout .card-footer {
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  margin: 20px 0 0;
  padding: 0;
}

.page-order .order-summary-bottom {
  background: linear-gradient(180deg, #3486c7, #619adc);
  box-shadow: 0 4px 10px rgba(41, 108, 237, 0.15);
  border-radius: 10px;
  color: #fff;
  flex-direction: column;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  height: auto;
  line-height: 24px;
  padding: 25px 30px;
  position: relative;
  text-align: center;
  width: 100%;
}

.page-order .order-summary-bottom:before {
  background-image: url(../img/reassurance-smyley-white.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  display: inline-block;
  height: 16px;
  margin: 0 8px -3px -12px;
  width: 24px;
}

/** ACCOUNT **/
.page-my-account h1 {
  text-align: center;
}
.page-my-account {
.account-links {
  margin: 100px auto 0;
  max-width: 710px;
  width: 75%;
  @media(max-width:767px)
  {
    display: block;
  }
}
}

.page-my-account {

.account-links{
 .account-link {
  border: 0.5px solid #c5dff2;
  border-radius: 10px;
  margin: 12.5px 10px;
  flex: 0 0 calc(50% - 20px);
  max-width: calc(50% - 20px);
  padding: 15px;
  text-align: center;
 @media(max-width:767px)
 {
    display: flex;
    max-width: 100%;
 }
  &:before {
    display: block;
    height: 25px;
  }

  .link-item {
    background: transparent;
    color: #3486c7;
    display: block;
    font-weight: 600;
    font-size: 18px;
    height: auto;
    line-height: 24px;
    margin: 15px 0 0;
    padding: 0;
    text-align: center;
    text-transform: inherit;
    @media(max-width:767px)
   {
      width: 100%;
    }
  }

}
}
}





.page-my-account .account-links #identity-link:before {
  content: url("../img/icon-user-account.svg");
}

.page-my-account .account-links #history-link:before {
  content: url("../img/icon-order-account.svg");
}

.page-my-account .account-links #addresses-link:before {
  content: url("../img/icon-delivery-account.svg");
}

.page-my-account .account-links #addresses-link:before,
.page-my-account .account-links #address-link:before,
.page-my-account .account-links #returns-link:before {
  content: url("../img/icon-delivery-account.svg");
}

.page-my-account .account-links #order-slips-link:before {
  content: url("../img/icon-avoir-account.svg");
}

.page-my-account .account-links #discounts-link:before {
  content: url("../img/icon-reduc-account.svg");
}

.page-my-account .account-reassurance {
  margin-top: 60px;
}

.page-my-account .account-reassurance .container {
  padding: 0;
}

.page-customer-account {
  .page-footer {
    display: flex;
    justify-content: center;
    width: 100%;

    .account-link {
      align-items: center;
      color: #4a4a4a;
      display: flex;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    .account-back-account:before {
      content: url("../img/icon-back-arrow.svg");
      display: block;
      margin-top: 5px;
      margin-right: 1rem;
    }

    .account-back-home:before {
      content: url("../img/icon-home.svg");
      display: block;
      margin-top: 5px;
      margin-right: 1rem;
    }

  }

}


#survey_slider #survey_title {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  color: #3486c7;
}

.featured-products {
  background-color: #f5f7fa;
  border-radius: 50px;
  padding: 50px;

  @media(max-width: 450px) {
    padding: 17px;
  }

  .card-product {
    min-width: min-content;
  }

  .products {
    justify-content: center;

    .product-miniature {
      flex: 0 0 20%;
      max-width: 15%;

      @media(max-width: 1370px) {
        margin: 0px 9px;
      }

      @media(max-width: 1199px) {
        flex: 0 0 33%;
        max-width: 24%;
      }

      @media(max-width: 991px) {
        margin: 0 31px;
      }

      @media(max-width: 767px) {
        flex: 0 0 33%;
        max-width: 50%;
        margin: 0;
      }

    }
  }

  .rc {
    background: none;

  }

  .card-img-top {
    &.product__card-img {
      width: 177px;
      margin-left: auto;
      margin-right: auto;

      @media(max-width:767px) {
        width: 140px;

      }

      @media(max-width:450px) {
        width: 140px;

      }

      @media(max-width:330px) {
        margin-left: -4px;
      }
    }
  }

  h2 {
    &.products-section-title {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 28px;

      @media(max-width: 767px) {
        font-size: 20px;
      }
    }
  }

  p {
    &.description {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: #212121;
      max-width: 720px;
      margin: 0 auto 50px auto;
    }
  }
}

.page-my-account {
  .account-links {
    #orthop-link {
      &::before {
        content: url(../img/document-check.svg);
        height: 25px;
        width: 25px;
        margin: auto;
      }
    }
  }
}

#psgdpr-link {
  &::before {
    content: url("../img/icon_lock.png");
  }
}

#bt_sl-link {
  &::before {
    content: url("../img/icon_link.png");

  }
}

.block-promo {
  margin-top: 18px;

  a {
    font: 500 14px 'Raleway';
  }
}

#promo-code {
  .promo-code__content {
    background-color: #f5f7fa;
    border: 1px solid #c5dff2;
  }

  .btn.btn-primary {
    padding: 8px 25px;
    margin-left: 12px;
  }

  .form-control {
    border: 1px solid #3486c7;
  }

  .cancel-promo {
    color: #3486c7;
  }
}

.shopping-cart {
  .cart-products-count {
    top: -5px;
    position: absolute;
    left: 29px;
    font: 600 15px Raleway;
    background: #3486c7;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    width: 20px;
    padding: 0px 2px 3px 3px;
  }
}

#mobile_top_menu_wrapper {
  &.modal {
    .modal-header {
      border-bottom: 0;
      display: grid;

      .close {
        float: left;
        text-align: left;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 17px 8px;
        width: 47px;
        height: 47px;
        background: #3486C7;
        border-radius: 50px;
        opacity: 1;

        span {
          font-size: 51px;
          font-weight: 100;
          color: #fff;
          text-shadow: 0 0px 0 #fff;
          margin-top: -7px;
        }
      }

    }

  }
}

.search-widget__group__mobile {
  float: right;
  position: relative;
  z-index: 999;
  top: 3px;
  margin-top: 16px;
  border: 0px;
  width: 100%;

  .form-control {
    &.search-widget__input-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 15px;
      gap: 11px;
      width: 300px;
      height: 45px;
      background: #FFFFFF;
      border: 1px solid #3486C7;
      border-radius: 100px;
    }
  }
}

#menu-icon:focus {
  box-shadow: none;
}

.search_bloc_tr {
  margin-bottom: 3px;
  width: 100%;
  position: relative;
  z-index: 999;
  top: 3px;
  margin-top: 16px;
  border: 0px;
  min-width: 300px;
}

@media(max-width:991px) {
  #header {
    .search-widget {
      display: none;
    }

    .header-nav__right {
      display: none;
    }
  }
}

@media(max-width:991px) {
  #content-wrapper {
    padding: 0;

    .container {
      padding: 0;
    }
  }
}

.modal-backdrop {
  background-color: #0000;
}

.products {
  .product-miniature {
    .card .goToProduct {
      a {

        padding: 12px 16px;

      }
    }
  }
}

.container,
.l-wrapper,
.l-wrapper--boxed,
main>.notifications-container {

  overflow: hidden;
}

#footer {
  @media(max-width:991px) {
    .container {
      padding: 0;
    }
  }
}
#popupstock
{
  background-color: rgba(0,0,0,.25);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  .modal-content {
    border: none;
    border-radius: 10px;
    box-shadow: none;
    padding: 40px;
    .modal-header {
      border: none;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0;
      button {
        align-items: center;
        display: flex;
        font-family: Raleway;
        font-size: 14px;
        font-weight: 400;
        justify-content: center;
        line-height: 16px;
        margin: 0;
        padding: 0;
      }
    }
    .modal-body
    {
    p {
      color: #4a4a4a;
      font: 400 14px 'Raleway';
      line-height: 24px;
      margin: 0 auto;
      max-width: 650px;
      width: 100%;
      text-align: center;
    }
    img {
      max-width: 100%;
      height: auto;
  }
    }
    .modal-footer {
      
        border: none;
      
    a{
      align-items: center;
     display: flex;
    &::before {
      background-image: url(../img/icon-union.svg);
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: 70%;
      content: "";
      display: block;
      margin-right: 10px;
      height: 24px;
      width: 24px;
    }
  }
}
  }
  h3 {
    font: 600 20px 'Raleway';
      line-height: normal;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
    color: #3486C7;
    margin: 20px auto;
  }
  
}

body {
  &#category{
   #wrapper {
          .page-content {
              margin-bottom: 0;
              h4 {
                font-family: Raleway;
                font-style: normal;
                font-weight: 700;
               
            }
              .tire_lait_searchbar {
                  &.main-searchbar-block {
                      position: relative;
                      width: 100%;
                      margin: 0;
                      min-height: 50px;

                      form.search-widget {
                          width: 100%;
                          max-width: 100%;
                          min-height: 50px;

                          .search-widget__group{
                          &.show {
                              width: 100%;
                              top: unset;
                              right: unset;
                              background: #f5f7fa;
                              .form-control {
                                  &.search-widget__input-right {
                                      height: 50px;
                                      width: 100%;
                                  }
                              }
                          }

                      }
                    }

                  }
              }
          }
      }
  }
}
body {
  &#search{
   #wrapper {
          .page-content {
              margin-bottom: 0;
              h4 {
                font-family: Raleway;
                font-style: normal;
                font-weight: 700;
               
            }
              .tire_lait_searchbar {
                  &.main-searchbar-block {
                      position: relative;
                      width: 100%;
                      margin: 0;
                      min-height: 50px;

                      form.search-widget {
                          width: 100%;
                          max-width: 100%;
                          min-height: 50px;

                          .search-widget__group{
                          &.show {
                              width: 100%;
                              top: unset;
                              right: unset;
                              background: #f5f7fa;
                              .form-control {
                                  &.search-widget__input-right {
                                      height: 50px;
                                      width: 100%;
                                  }
                              }
                          }

                      }
                      }

                  }
              }
          }
      }
  }
}
  
#popup-teterelle-content 
{
.teterelle-content {
  width: 100%;
  height: 100%;
  overflow: scroll;
}
}
#content-checkout-personal-information-step
{
  .content
  {
&.card-body
{ 
  @media(max-width:767px)
  {
    padding: 0px;
  }
  
}
  }
}


body {
    &#pagenotfound {
        #wrapper {
            .page-content {
                margin-bottom: 0;

                .tire_lait_searchbar {
                    &.main-searchbar-block {
                        position: relative;
                        width: 100%;
                        margin: 0;
                        min-height: 50px;

                        form.search-widget {
                            width: 100%;
                            max-width: 100%;
                            min-height: 50px;

                            .search-widget__group.show {
                                width: 100%;
                                top: unset;
                                right: unset;

                                .form-control {
                                    &.search-widget__input-right {
                                        height: 50px;
                                        width: 100%;
                                    }
                                }
                            }

                        }

                    }
                }
            }
        }
    }
}